import * as React from "react";

const SvgTerms = (props) => (
  <svg
    id="Terms_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 31.4 31.4"
    style={{
      enableBackground: "new 0 0 31.4 31.4",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".Terms_svg__st0{fill:#4d4d4d}.Terms_svg__st1{fill:#fcfcfc}.Terms_svg__st3{fill:#050909}.Terms_svg__st4{fill:#b2b1b1}.Terms_svg__st5{fill:none}.Terms_svg__st6{clip-path:url(#Terms_svg__SVGID_2_)}.Terms_svg__st7{clip-path:url(#Terms_svg__SVGID_4_)}.Terms_svg__st8{clip-path:url(#Terms_svg__SVGID_6_)}.Terms_svg__st9{clip-path:url(#Terms_svg__SVGID_8_)}.Terms_svg__st10{clip-path:url(#Terms_svg__SVGID_10_)}.Terms_svg__st11{fill:#74c1e6}.Terms_svg__st12{fill:#f5d907}.Terms_svg__st13{fill:#d53844}.Terms_svg__st14{fill:#78b45c}"
      }
    </style>
    <path
      d="M28.6 30.7H2.7c-1.1 0-2.1-.9-2.1-2.1V2.7C.6 1.6 1.5.6 2.7.6h25.9c1.1 0 2.1.9 2.1 2.1v25.9c0 1.2-.9 2.1-2.1 2.1z"
      style={{
        fill: "#FFF",
      }}
    />
    <path d="M28.6 31.4H2.7c-1.5 0-2.7-1.2-2.7-2.7v-26C0 1.2 1.2 0 2.7 0h25.9c1.5 0 2.7 1.2 2.7 2.7v25.9c.1 1.5-1.2 2.8-2.7 2.8zM2.7 1.4c-.7 0-1.3.6-1.3 1.3v25.9c0 .8.6 1.4 1.3 1.4h25.9c.8 0 1.4-.6 1.4-1.4V2.7c0-.8-.6-1.4-1.4-1.4H2.7z" />
    <path d="M13.7 24.7c-.1-.3-.1-.6-.2-1l4.8-2.1c0 .3 0 .6.1.8-1.6.7-3.1 1.5-4.7 2.3m-.4-2.5c-.1-.3-.1-.7-.2-1 2.4-.9 4.9-1.7 7.4-2.4v.8c-2.5.8-4.9 1.7-7.2 2.6m-.4-2.5c0-.4-.1-.7-.1-1.1 2.5-.6 5-1.2 7.6-1.7v.8c-2.6.6-5.1 1.3-7.5 2m-.3-2.7c0-.4-.1-.8-.1-1.1 2.3-.4 4.6-.7 7-.9 0 .3 0 .6-.1.9-2.3.3-4.5.7-6.8 1.1m-.2-2.8V13c2.9-.2 5.8-.3 8.8-.3 0 .3-.1.6-.1.9-2.9.1-5.8.3-8.7.6m0-3V10c2.8.1 5.7.3 8.5.5-.1.3-.1.6-.2.9-2.8-.1-5.5-.2-8.3-.2m.1-3.1c0-.4.1-.9.1-1.3.6.1 1.3.2 1.9.3 0 .4-.1.8-.1 1.2-.7-.1-1.3-.1-1.9-.2m3.5.4c0-.4.1-.8.1-1.2 1.1.2 2.1.3 3.2.5-.1.3-.1.7-.2 1-1-.1-2.1-.2-3.1-.3m8.2-1.8L10.4 3.4c-.7 8.3.1 16.5 2.3 24.2 2.9-1.9 6-3.6 9.3-5.1-.2-5.5.6-10.8 2.2-15.8" />
    <path d="M10.1 4c-.6-.1-1.1-.2-1.7-.3-.4 8.2.7 16.3 3.1 23.9.2-.2.5-.3.7-.5-1.9-7.4-2.7-15.2-2.1-23.1z" />
    <path d="M8 4.3c-.3-.1-.6-.1-.9-.2-.2 8 1 15.7 3.6 23 .1-.1.2-.1.3-.2-2.3-7.2-3.3-14.8-3-22.6z" />
  </svg>
);

export default SvgTerms;
