import { Fragment } from "react";
import BoxTitle from "../../../TEMPLATE/components/box/title/BoxTitle";
import { useTranslation } from "react-i18next";

const links = [
  {
    name: "Πανεπιστήμιο Πατρών",
    url: "http://www.patrasair.gr",
  },
  {
    name: "Περιφέρεια Δυτικής Ελλάδας",
    url: "https://www.pde.gov.gr/gr/enimerosi/anartitea/airpollutionofpatras.html",
  },
];

const CustomCategoryUniversity = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <BoxTitle
        icon={"im im-icon-Globe"}
        title={t("ΠΕΡΙΒΑΛΛΟΝΤΙΚΕΣ ΜΕΤΡΗΣΕΙΣ")}
        infoContext={"info"}
        element={
          <i
            id="draggable"
            className="im im-icon-Double-Tap"
            style={{ fontSize: "32px", minWidth: "32px", cursor: "pointer" }}
          />
        }
      />

      <p
        style={{
          marginTop: "20px",
          fontSize: "14px",
        }}
      >
        {t("Δείτε περισσότερες μετρήσεις που πραγματοποιούνται από:")}
      </p>
      <div style={{ marginTop: 20 }} />
      {links.map((item, index) => (
        <button
          key={index}
          type={"button"}
          style={{
            width: 200,
            height: 45,
            marginLeft: "calc((100% - 200px ) /2)",
            marginBottom: 10,
            borderRadius: "30px",
            border: "none",
            cursor: "pointer",
          }}
          onClick={() => window.open(item.url, "_blank")}
        >
          <span style={{ fontWeight: "600", fontSize: "15px" }}>
            {t(item.name)}
          </span>
        </button>
      ))}
    </Fragment>
  );
};
export default CustomCategoryUniversity;
