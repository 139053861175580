import TermsOfUseEl from "../components/TermsOfUse/TermsOfUse";

const TermsOfUse = () => {
  return (
    <div>
      <TermsOfUseEl />  
    </div>
  );
};

export default TermsOfUse;
